/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */
import * as Sentry from '@sentry/remix'
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import { startTransition, StrictMode, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { TooltipProvider } from '~/components/plate-ui/tooltip'

Sentry.init({
    dsn: 'https://341e3aaee365d020f07bebca9f907b5d@sentry.technochezh.ru/8',
    tracesSampleRate: 1,

    integrations: [
        Sentry.browserTracingIntegration({
            useEffect,
            useLocation,
            useMatches,
        }),
        Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
})

startTransition(() => {
    hydrateRoot(
        document,
        <StrictMode>
            <TooltipProvider
                disableHoverableContent
                delayDuration={500}
                skipDelayDuration={0}
            >
                <RemixBrowser />
            </TooltipProvider>
        </StrictMode>
    )
})
